<template>
  <v-row class="pa-2">
    <v-col cols="12">
      <v-alert type="error" text v-if="app_error">
        {{ app_error }}
      </v-alert>

      <v-dialog v-model="delete_dialog" width="500" v-if="group_to_delete">
        <v-card>
          <v-card-title class="headline" primary-title>
            Delete
            <span style="color:#7192B0;font-weight:bolder;" class="pl-2">{{
              group_to_delete.name
            }}</span
            >?
          </v-card-title>

          <v-card-text>
            This action cannot be undone.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="group_to_delete = null">CANCEL</v-btn>
            <v-btn color="error darken-1" @click="delete_group(group_to_delete)"
              >DELETE</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="primary_color_dialog"
        v-if="temp_settings"
        max-width="700"
      >
        <v-card class="pa-2">
          <v-toolbar dark dense color="primary" class="mb-4">
            <v-toolbar-title>
              <v-icon>mdi-wrench</v-icon>
              <strong>{{ selected_group.name }}</strong> Portal Style
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="primary_color_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="ma-0 pa-0">
            <v-card
              :color="get_bg_color(temp_settings)"
              class="ma-2 mb-4"
              style="border:5px solid #666"
            >
              <v-app-bar style="max-height:56px;border-radius: 0" color="#eee">
                <a
                  href="/"
                  style="padding:0;padding-right:5px;text-decoration:none;"
                >
                  <span class="logo-lg" style="color:#7192B0;font-size:.80em;">
                    🧙
                    <b style="color:#053768;font-size:1.2em">AUTH</b
                    >.certipath.io</span
                  >
                </a>
              </v-app-bar>
              <v-container fluid>
                <v-row align="center" justify="center">
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                    align="center"
                    justify="center"
                    class="pb-0"
                  >
                    <v-card
                      class="elevation-0"
                      :color="temp_settings.secondary_color"
                    >
                      <v-card-text class="pa-0">
                        <img
                          src="../../src/assets/initech.jpg"
                          id="output"
                          width="100"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row align="center" justify="center">
                  <v-col cols="12" sm="8" md="6">
                    <v-card>
                      <v-card-text class="pt-6 mt-0 mb-0 pb-4">
                        Choose a certificate
                        <v-list nav class="pa-0 ma-0">
                          <v-list-item
                            style="height:69px;border:1px solid #ddd;border-left: 5px solid #666;background-color:#eee;"
                          >
                            <v-list-item-icon class="mr-2">
                              <v-icon class="mt-2" color="#666"
                                >mdi-plus</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content
                              style="padding-top:17px;color:#666;font-weight:bold;"
                            >
                              Add New Certificate
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <v-file-input
              id="logo_upload"
              v-model="temp_settings.image"
              accept=".jpg, .jpeg, .png, .gif"
              label="Upload Logo Image"
            ></v-file-input>

            <v-text-field
              label="Primary Color"
              v-model="temp_settings.primary_color"
              class="ma-2 mb-0 mt-0"
              hide-inputs
            ></v-text-field>
            <v-text-field
              label="Secondary Color"
              v-model="temp_settings.secondary_color"
              class="ma-2 mb-0 mt-0"
              hide-inputs
            ></v-text-field>

            <v-checkbox
              v-model="temp_settings.is_dark"
              label="Use Dark Mode"
            ></v-checkbox>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="primary_color_dialog = false">
              Cancel
            </v-btn>
            <v-btn
              color="success"
              @click="
                primary_color_dialog = false;
                save_style();
              "
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="configure_dialog"
        v-if="selected_group"
        max-width="755"
      >
        <v-card class="pa-2">
          <v-toolbar dark dense color="primary" class="mb-4">
            <v-toolbar-title>
              <v-icon>mdi-wrench</v-icon>
              <strong>{{ selected_group.name }}</strong>
              <span style="font-size:.7em" class="ml-2"
                >Trust Configuration</span
              >
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="configure_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-list two-line subheader dense>
            <v-subheader style="border-bottom:1px solid #ddd;">
              Validation Paths
              <v-spacer></v-spacer>
              <v-btn
                small
                class="success"
                style="float:right;"
                @click.stop="new_rule_dialog = true"
              >
                <v-icon small>mdi-plus</v-icon>
                Add Root
              </v-btn>
            </v-subheader>
            <PathRule
              v-for="test in group_for_new_rule.group.path_rules"
              :group="group_for_new_rule.group"
              :rule="test"
              :key="test.id"
            ></PathRule>
          </v-list>
          <v-dialog v-model="new_rule_dialog" width="500">
            <NewPathRule
              v-if="group_for_new_rule"
              :group="group_for_new_rule"
              @add-path-rule="add_path_rule"
              @close-dialog="new_rule_dialog = false"
            ></NewPathRule>
          </v-dialog>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="settings_dialog"
        v-if="selected_group"
        max-width="500px"
      >
        <v-card class="pa-2">
          <v-toolbar dark dense color="primary" class="mb-4">
            <v-toolbar-title>
              <v-icon>mdi-wrench</v-icon>
              <strong>{{ selected_group.name }}</strong>
              <span style="font-size:.7em" class="ml-2">
                Authentication Settings</span
              >
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="settings_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-text-field
              dense
              class="mb-3 mt-2"
              hide-details
              outlined
              label="Redirect URIs (space seperated)"
              v-model="selected_group.redirect_uris"
            ></v-text-field>
            <v-text-field
              dense
              class="mb-3"
              hide-details
              outlined
              label="Authentication Cache Time (seconds)"
              v-model="selected_group.authentication_cache_seconds"
            ></v-text-field>

            <v-text-field
              dense
              class="mb-3"
              hide-details
              outlined
              label="Validation Cache Time (seconds)"
              v-model="selected_group.validation_cache_seconds"
            ></v-text-field>

            <v-select
              v-model="selected_group.revocation_checking_behavior"
              :items="revocation_checking_behaviors"
              label="Revocation Checking Behavior"
              item-text="name"
              item-value="value"
              outlined
              dense
              class="mb-3 mt-4"
              hide-details
            >
            </v-select>

            <v-checkbox
              v-model="selected_group.revocation_checking_fallback"
              label="Revocation Checking Fallback"
              item-text="name"
              item-value="value"
              outlined
              dense
              class="mb-3"
              hide-details
            >
            </v-checkbox>

            <v-text-field
              dense
              class="mb-3"
              hide-details
              outlined
              label="DN Substring Match"
              v-model="selected_group.end_entity_name_substring_match"
            ></v-text-field>
            <div style="display:none;">
              <v-text-field
                dense
                class="mb-3"
                hide-details
                outlined
                label="Initial Policy Set"
                v-model="selected_group.initial_policy_set"
              ></v-text-field>

              <v-checkbox
                dense
                hide-details
                label="Initial require explicit policy"
                v-model="selected_group.initial_require_explicit_policy"
                class="mb-3"
              ></v-checkbox>
              <v-checkbox
                dense
                hide-details
                label="initial inhibit policy mapping"
                v-model="selected_group.initial_inhibit_policy_mapping"
                class="mb-3"
              ></v-checkbox>
              <v-checkbox
                dense
                hide-details
                label="Enable revocation checking"
                v-model="selected_group.enable_revocation_checking"
                class="mb-3"
              ></v-checkbox>
              <v-checkbox
                dense
                hide-details
                label="Revocation checking EE only"
                v-model="selected_group.revocation_checking_ee_only"
                class="mb-3"
              ></v-checkbox>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="settings_dialog = false">
              Cancel
            </v-btn>
            <v-btn @click="testScope()" color="primary">Test</v-btn>
            <v-btn
              @click="
                save_settings();
                settings_dialog = false;
              "
              color="success"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="admins_dialog" v-if="selected_group" max-width="500px">
        <v-card class="pa-2">
          <v-toolbar dark color="primary" class="mb-4">
            <v-toolbar-title>
              <v-icon>mdi-wrench</v-icon>
              <strong>{{ selected_group.name }}</strong>
              <span style="font-size:.7em" class="ml-2"> Administrators</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="admins_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-alert type="info" text>
            All admins can modify this applications settings.
          </v-alert>

          <v-select
            v-model="selected_group.admins"
            :items="customer_users"
            chips
            label="Administrators"
            item-text="email"
            item-value="id"
            multiple
            outlined
            deletable-chips
            hide-details
            class="mb-3"
            v-on:change="update_admins"
            return-object
          ></v-select>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="credentials_dialog"
        v-if="selected_group"
        max-width="500px"
      >
        <v-card class="pa-2 pb-4">
          <v-toolbar dark color="primary" class="mb-4">
            <v-toolbar-title>
              <v-icon class="mt-n1">mdi-eye</v-icon>
              <strong>{{ selected_group.name }}</strong>
              <span style="font-size:.7em" class="ml-2">Credentials </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="credentials_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-alert type="info" text>
            Use these to configure your application.
            <br />
            <a href="/help/oauth2">CertiPath OAuth2 Guide</a>
          </v-alert>
          <ChipValue
            label="Client ID"
            :value="selected_group.client_id"
            copy="true"
          ></ChipValue>
          <v-divider class="mt-4 mb-4"></v-divider>
          <ChipValue
            label="Client Secret"
            :value="selected_group.client_secret"
            copy="true"
          ></ChipValue>
        </v-card>
      </v-dialog>

      <v-data-table
        :headers="app_headers"
        :items="applications"
        :loading="loading"
        class="elevation-1"
        :sort-by="['name']"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>TrustAuth Applications</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="log_dialog" max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  small
                  text
                  outlined
                  ><v-icon>mdi-eye</v-icon> Access Logs</v-btn
                >
              </template>
              <v-card>
                <v-data-table
                  :headers="log_headers"
                  :items="logs"
                  :loading="logs_loading"
                  class="elevation-1"
                  :sort-by="['validation_time']"
                  :sort-desc="true"
                  :mobile-breakpoint="0"
                  :search="log_search"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Access Logs</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-text-field
                        hide-details
                        dense
                        class="mt-2"
                        clearable
                        prepend-icon="mdi-magnify"
                        aria-label="Search"
                        v-model="log_search"
                      >
                      </v-text-field>
                      <v-btn
                        color="gray"
                        text
                        class="mb-2"
                        @click.stop="update_logs()"
                        ><v-icon>mdi-refresh</v-icon></v-btn
                      >
                    </v-toolbar>
                  </template>
                </v-data-table>
              </v-card>
            </v-dialog>
            <v-dialog v-model="create_dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-plus</v-icon> Add Application</v-btn
                >
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Create a new application</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-text-field
                      label="Application Name"
                      hide-details
                      dense
                      v-model="new_app_name"
                      outlined
                      required
                    ></v-text-field>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary darken-1"
                    text
                    @click="create_dialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn color="success darken-1" @click="create_new_app()"
                    >Create</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn
              color="gray"
              text
              class="mb-2"
              @click.stop="update_applications()"
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:item.primary_color="{ item }">
          <v-progress-circular
            v-if="item.saving"
            indeterminate
            color="secondary"
            style="float:left;"
            class="ml-4 mr-2 mt-1"
          ></v-progress-circular>

          <v-card
            v-else
            :color="get_bg_color(item)"
            class="mt-2 mb-2"
            :style="`border-left:12px solid ${item.primary_color}`"
            @click="
              selected_group = item;
              temp_settings = JSON.parse(JSON.stringify(item));
              primary_color_dialog = true;
            "
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-card
                class="ma-2"
                :style="
                  `width:80px;height:40px;background-color:${item.secondary_color}`
                "
              >
                <img height="40" src="../../src/assets/initech.jpg" />
              </v-card>
            </div>
          </v-card>
        </template>

        <template v-slot:item.name="{ item }" class="pa-0">
          <v-text-field
            hide-details
            dense
            v-model="item.name"
            outlined
            class="pl-0"
            @blur="update_name($event.target.value, item)"
          >
            <template v-slot:append class="pa-0 ma-0">
              <v-btn
                color="error darken-1"
                icon
                class="ma-0 pa-0 mr-n2"
                @click="
                  group_to_delete = item;
                  delete_dialog = true;
                "
                ><v-icon>mdi-trash-can-outline</v-icon></v-btn
              >
            </template>
          </v-text-field>
        </template>

        <template v-slot:item.admins="{ item }">
          <v-btn
            @click="
              selected_group = item;
              admins_dialog = true;
            "
            color="primary"
            text
            ><v-icon class="mr-2">mdi-account-tie</v-icon>
            {{ item.admins.length }}</v-btn
          >
        </template>

        <template v-slot:item.settings="{ item }">
          <v-btn
            small
            color="secondary"
            @click="
              selected_group = item;
              settings_dialog = true;
            "
            ><v-icon small class="mr-2">mdi-wrench</v-icon> Configure</v-btn
          >

          <v-btn
            small
            @click="
              selected_group = item;
              credentials_dialog = true;
            "
            color="primary"
            class="ml-2"
          >
            <v-icon small class="mr-2">mdi-eye</v-icon> Credentials
          </v-btn>
        </template>

        <template v-slot:item.monitoring_group="{ item }">
          <v-btn
            text
            color="primary"
            @click="
              selected_group = item;
              configure_dialog = true;
            "
            ><v-icon class="mr-2">mdi-certificate-outline</v-icon></v-btn
          >
        </template>

        <template v-slot:loading>
          <LoadingSVG />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
//import Application from "../components/portal/Application";
import UserService from "../store/UserService";
import LoadingSVG from "../components/LoadingSVG";
import ChipValue from "../components/ChipValue";
import NewPathRule from "../components/groups/rules/NewPathRule";
import PathRule from "../components/groups/rules/PathRule";
import axios from "axios";

export default {
  name: "PortalApplications",
  components: { NewPathRule, ChipValue, LoadingSVG, PathRule },
  data: function() {
    return {
      log_dialog: false,
      log_search: null,
      log_headers: [
        {
          text: "Time",
          align: "left",
          sortable: true,
          value: "validation_time",
          export: false,
          width: 60
        },
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
          export: false,
          width: 60
        },
        {
          text: "Result",
          align: "left",
          sortable: true,
          value: "reason",
          export: false,
          width: 60
        },
        {
          text: "IP",
          align: "left",
          sortable: true,
          value: "ip",
          export: false,
          width: 60
        }
      ],
      logs: [],
      logs_loading: false,
      new_rule_dialog: false,
      applications: [],
      expanded: false,
      loading: true,
      color_dialog: false,
      color_picked: null,
      rule_types: ["path"],
      new_rule_type: "path",
      rule_dialog: false,
      rules_loading: false,
      roots: [],
      roots_loading: false,
      new_path_rule_issued_to: null,
      new_path_rule_policies: null,
      new_rule_cas: true,
      new_rule_ees: false,
      new_path_rule_using: 1,
      root_policies: [],
      root_policies_loading: false,
      selected_group: null,
      configure_dialog: false,
      settings_dialog: false,
      credentials_dialog: false,
      admins_dialog: false,
      primary_color_dialog: false,
      secondary_color_dialog: false,
      customer_users_loading: true,
      temp_settings: null,
      new_app_name: null,
      new_app_admins: [],
      new_app_logo: null,
      group_to_delete: null,
      delete_dialog: false,
      duplicate_root: false,
      path_to_edit: null,
      rule_headers: [
        {
          text: " ",
          align: "left",
          sortable: false,
          value: "id",
          export: false,
          width: 60
        },
        {
          text: "Rule",
          align: "left",
          sortable: true,
          value: "name",
          export: true
        },
        {
          text: "Certificates",
          align: "left",
          sortable: true,
          value: "certs",
          export: true
        }
      ],
      trusted_cert_options: [
        {
          name: "CertiPath Demo Root",
          value: "/var/www/TrustAuth/CertVal/cfg/demo.cer"
        },
        {
          name: "Federal Common Policy",
          value:
            "/var/www/TrustAuth/portal/static/user_1/trusted/trusted_WRVgbO2.p7b"
        }
      ],
      intermediate_cert_options: [
        {
          name: "CertiPath Demo",
          value: "/var/www/TrustAuth/CertVal/cfg/demo.cer"
        },
        {
          name: "PIV Authentication",
          value: "/var/www/TrustAuth/CertVal/cfg/piv.p7b"
        },
        {
          name: "PIV-I Authentication",
          value: "/var/www/TrustAuth/CertVal/cfg/pivi.p7b"
        },
        {
          name: "All Valid to Common",
          value:
            "/var/www/TrustAuth/portal/static/user_1/intermediate/all_from_common1.p7b"
        }
      ],
      test_requirements: [
        {
          id: 0,
          name: "Require all passed scopes",
          tip:
            "No user will be redirected unless their certificate meets all tests."
        },
        {
          id: 1,
          name: "Require one or more pass",
          tip:
            "Users will be forwarded if at least one test passes. Application must interpret test results."
        },
        {
          id: 2,
          name: "Accept all certificates",
          tip:
            "All users will be forwarded with any certificate. Application must interpret test results."
        }
      ],
      revocation_checking_behaviors: [
        { value: 2, name: "Prefer OCSP" },
        { value: 1, name: "Prefer CRLs" }
      ],
      revocation_checking_fallbacks: [
        { value: true, name: "Fallback" },
        { value: false, name: "No Fallback" }
      ],
      monitoring_group_options: [],
      customer_users: [],
      app_error: null,
      create_dialog: false,
      app_headers: [
        {
          text: "Style",
          align: "center",
          sortable: false,
          value: "primary_color",
          width: 120,
          export: true
        },
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
          export: true
        },
        {
          text: "Admins",
          align: "left",
          sortable: false,
          value: "admins",
          export: true
        },
        {
          text: "Trusted Certificates",
          align: "left",
          sortable: false,
          value: "monitoring_group",
          export: true
        },
        {
          text: "Settings",
          align: "left",
          sortable: false,
          value: "settings",
          export: true
        }
      ]
    };
  },
  computed: {
    group_for_new_rule: function() {
      let self = this;
      if (!this.selected_group) {
        return null;
      }
      return this.$store.getters.monitoring_groups.filter(function(value) {
        return value.group.id === self.selected_group.monitoring_group;
      })[0];
    },
    new_rule_name: function() {
      if (this.new_rule_type === "name") {
        if (this.new_basic_rule_issued_to && this.new_basic_rule_issued_by) {
          return (
            "Issued by " +
            this.new_basic_rule_issued_by.friendly_name +
            " to " +
            this.new_basic_rule_issued_to.friendly_name
          );
        } else if (this.new_basic_rule_issued_by) {
          return "Issued by " + this.new_basic_rule_issued_by.friendly_name;
        } else if (this.new_basic_rule_issued_to) {
          return "Issued to " + this.new_basic_rule_issued_to.friendly_name;
        } else {
          return "All Certificates";
        }
      } else if (this.new_path_rule_issued_to) {
        return (
          "Valid to " + this.new_path_rule_issued_to.subject_fqdn.friendly_name
        );
      } else {
        return "No name yet";
      }
    }
  },
  mounted() {
    this.update_customer_users();
    this.update_applications();
    this.update_logs();
  },
  methods: {
    update_logs: function() {
      this.logs_loading = true;
      axios.get("https://demoauth.certipath.io/getlogs/").then(response => {
        console.log(response.data.data);
        this.logs = response.data.data;
        this.logs_loading = false;
      });
    },
    update_applications: function() {
      this.loading = true;
      this.applications = [];
      UserService.getLocalApi("applications/").then(response => {
        this.applications = response.data.results;
        this.loading = false;
      });
    },
    update_customer_users() {
      UserService.getLocalApi("public-users/").then(response => {
        this.customer_users = response.data.results;
        this.customer_users_loading = false;
      });
    },
    update_name: function(event, item) {
      let self = this;
      this.selected_group = item;
      this.selected_group.saving = true;

      UserService.patchLocalApi(
        "applications/" + self.selected_group.id + "/",
        {
          name: self.selected_group.name
        }
      )
        .then(response => {
          self.applications = self.applications.filter(function(value) {
            return value.id !== self.selected_group.id;
          });
          this.applications.push(response.data);

          this.selected_group.saving = false;
        })
        .catch(err => console.log(err.response.data));
    },
    delete_group: function(group) {
      let self = this;
      self.group_to_delete = null;
      self.groups = [];
      self.groups_loading = true;
      self.group_error = null;
      UserService.deleteLocalApi("applications/" + group.id + "/")
        .then(function() {
          self.applications = self.applications.filter(function(value) {
            return value.id !== group.id;
          });
        })
        .catch(err => console.log(err.response.data));
    },
    update_test: function() {
      alert("hello?");
    },
    update_selected_app: function() {
      let self = this;
      let updating_id = self.selected_group.id;

      this.selected_group.saving = true;

      UserService.getLocalApi("applications/" + updating_id + "/").then(
        response => {
          self.applications = self.applications.filter(function(value) {
            return value.id !== self.selected_group.id;
          });
          this.applications.push(response.data);

          this.selected_group.saving = false;
        }
      );
    },
    save_style: function() {
      let self = this;
      this.selected_group.saving = true;
      // app.selected_group.settings = app.temp_settings;
      var formData = new FormData();

      var imagefile = document.querySelector("#logo_upload");
      console.log(self.temp_settings);
      if (self.temp_settings.image) {
        console.log("here");
        formData.append("image", imagefile.files[0]);
      }

      formData.append("secondary_color", self.temp_settings.secondary_color);
      formData.append("primary_color", self.temp_settings.primary_color);
      formData.append("is_dark", self.temp_settings.is_dark);
      UserService.patchLocalApi(
        "applications/" + self.selected_group.id + "/",
        formData,
        {
          "Content-Type": "multipart/form-data"
        }
      )
        .then(response => {
          console.log(response);
          self.selected_group.settings = self.temp_settings;
          self.update_selected_app();
        })
        .catch(err => console.log(err.response.data));
    },
    preview_file: function(files) {
      var output = document.getElementById("output");
      console.log(output);
      console.log(files);
      console.log("attempting preview");
      if ("srcObject" in output) {
        output.src = files[0];
      } else {
        output = window.URL.createObjectURL(files[0]);
      }
      output.onload = function() {
        URL.revokeObjectURL(output.src); // free memory
      };
    },
    save_settings: function() {
      let self = this;
      let group = self.selected_group;
      self.selected_group.saving = true;
      UserService.patchLocalApi("applications/" + group.id + "/", {
        redirect_uris: group.redirect_uris,
        authentication_cache_seconds: group.authentication_cache_seconds,
        validation_cache_seconds: group.validation_cache_seconds,
        revocation_checking_behavior: group.revocation_checking_behavior,
        revocation_checking_fallback: group.revocation_checking_fallback,
        validation_type: group.validation_type,
        end_entity_name_substring_match: group.end_entity_name_substring_match
      })
        .then(response => {
          self.applications = self.applications.filter(function(value) {
            return value.id !== self.selected_group.id;
          });
          this.applications.push(response.data);
        })
        .catch(err => console.log(err.response.data));
    },
    update_admins: function(val) {
      let self = this;
      this.selected_group.saving = true;
      UserService.patchLocalApi(
        "applications/" + self.selected_group.id + "/",
        {
          admins: val
        }
      )
        .then(response => {
          console.log(response);
          this.selected_group.saving = false;
          this.update_selected_app();
        })
        .catch(err => console.log(err.response.data));
    },
    get_bg_color: function(item) {
      if (item.is_dark) {
        return "#303030";
      } else {
        return "#eee";
      }
    },
    get_scope: function(ap) {
      return [
        {
          //name: 'default',
          //optional: false,
          // monitoring_group: ap.monitoring_group,
          validation_cache_seconds: ap.validation_cache_seconds,
          authentication_cache_seconds: ap.authentication_cache_seconds,
          initial_require_explicit_policy: ap.initial_require_explicit_policy,
          initial_inhibit_policy_mapping: ap.initial_inhibit_policy_mapping,
          revocation_checking_behavior: ap.revocation_checking_behavior,
          revocation_checking_fallback: ap.revocation_checking_fallback,
          enable_revocation_checking: ap.enable_revocation_checking,
          revocation_checking_ee_only: ap.revocation_checking_ee_only,
          change_time_if_expired: ap.change_time_if_expired,
          initial_policy_set: ap.initial_policy_set,
          //trusted_certs: ap.trusted_certs,
          //intermediate_certs: ap.intermediate_certs,
          end_entity_name_substring_match: ap.end_entity_name_substring_match
        }
      ];
    },
    testScope: function() {
      let self = this;
      let ap = this.selected_group;
      let target_url =
        "https://demoauth.certipath.io/quick/?client_id=" +
        ap.client_id +
        "&response_type=code&scope=certificate&redirect_uri=https://demo.certipath.io/settings/applications/";
      target_url += "&test_client_id=" + ap.client_id;
      target_url +=
        "&test_scope=" +
        encodeURIComponent(JSON.stringify(self.get_scope(ap)[0]));

      window.open(target_url, "_blank");
    },
    saveApplication: function(ap) {
      console.log(ap);
    },
    create_new_app: function() {
      UserService.postLocalApi("applications/", { name: this.new_app_name })
        .then(response => {
          self.create_dialog = false;
          self.applications.push(response.data);
        })
        .catch(err => console.log(err.response.data));
    },
    add_path_rule: function(group, rule) {
      console.log("adding path rule");
      group.group.path_rules.push(rule);
    },
    delete_rule: function() {},
    edit_policies: function(rule) {
      this.path_to_edit = rule;
    }
  }
};
</script>

<style scoped></style>
