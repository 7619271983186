<template>
  <v-skeleton-loader
    class="mx-auto mt-2"
    type="image"
    style="height:180px;"
    v-if="!issued_urls"
  ></v-skeleton-loader>
  <v-card class="pa-0 mt-2" v-else>
    <v-card-title dense>
      <template v-if="!issued_urls_loading">
        <span v-if="uri_type_list.length === 1">
          {{ issued_urls.length }} Monitored {{ uri_type_list[0] }}s
        </span>
        <span v-else-if="uri_type_list.length === 0">
          {{ issued_urls.length }} Monitored URLs
        </span>
        <span v-else> {{ issued_urls.length }} Filtered URLs </span>
      </template>

      <span v-else>
        Loading...
      </span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="issued_urls_search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        aria-label="Search"
      ></v-text-field>
      <v-select
        label="Filter"
        outlined
        v-model="uri_type_list"
        style="max-width:200px;"
        :items="uri_types"
        multiple
        dense
        hide-details
        class="mt-1 ml-2"
      >
      </v-select>
    </v-card-title>
    <v-data-table
      :headers="url_table_headers"
      :items="issued_urls"
      class="elevation-1"
      :loading="issued_urls_loading"
      :search="issued_urls_search"
      :items-per-page="5"
      dense
    >
      <template v-slot:item.uri="{ item }">
        <Generic :url="item"></Generic>
      </template>

      <template v-slot:loading>
        <LoadingSVG />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import LoadingSVG from "../../LoadingSVG";
import UserService from "../../../store/UserService";
import moment from "moment";
import Generic from "../../uris/Generic";

export default {
  name: "GroupRuleUriTable",
  components: { LoadingSVG, Generic },
  props: ["rule_type", "rule_id", "uri_type"],
  data: function() {
    return {
      uri_type_list: [],
      uri_types: ["AIA", "SIA", "CRL", "OCSP"],
      issued_urls_loading: true,
      issued_urls_search: "",
      issued_urls: [],
      url_table_headers: [{ text: "URL", sortable: true, value: "uri" }]
    };
  },
  mounted: function() {
    this.uri_type_list = [this.uri_type];
    this.doSearch();
  },
  watch: {
    uri_type_list: function() {
      this.doSearch();
    },
    uri_type: function() {
      this.uri_type_list = [this.uri_type];
      this.doSearch();
    },
    rule_id: function() {
      this.issued_urls = [];
      this.uri_type_list = [this.uri_type];
      this.doSearch();
    }
  },
  methods: {
    doSearch: function() {
      this.issued_urls_loading = true;
      let filter = {};
      if (this.rule_type === "path") {
        filter["path"] = [this.rule_id];
        filter["ca"] = [];
      } else {
        filter["path"] = [];
        filter["ca"] = [this.rule_id];
      }
      UserService.getLocalApi(
        "uris/?size=10000&filter_group_rules=" +
          JSON.stringify(filter) +
          "&include_types=" +
          this.uri_type_list.toString().toLowerCase()
      )
        .then(response => {
          this.issued_urls = response.data.results;
          this.issued_urls_loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    afterNow: function(time) {
      return moment(time).isAfter(moment());
    }
  }
};
</script>

<style scoped></style>
