<template>
  <v-list-item dense style="border-top:1px solid #ddd" v-if="!deleted">
    <v-dialog v-model="dialog" width="800" class="pa-0">
      <v-lazy>
        <v-card
          :style="`border-radius:0;border-left: 15px solid ${group.color}`"
        >
          <v-toolbar class="pb-4">
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ rule.name }}</v-toolbar-title>
          </v-toolbar>
          <CertificateTable
            v-if="dialog_type === 'cert'"
            :certificates="certs"
          />
          <GroupRuleUriTable
            v-else
            rule_type="path"
            :rule_id="rule.id"
            :uri_type="dialog_type"
          >
          </GroupRuleUriTable>
        </v-card>
      </v-lazy>
    </v-dialog>
    <v-overlay absolute :value="deleting" opacity=".9" color="primary">
      <v-progress-circular
        indeterminate
        size="28"
        class="mr-2"
      ></v-progress-circular>
      Deleting...
    </v-overlay>
    <v-list-item-avatar>
      <v-btn color="error darken-1" text icon @click="delete_rule"
        ><v-icon>mdi-trash-can-outline</v-icon></v-btn
      >
    </v-list-item-avatar>
    <v-list-item-title style="max-width:400px;">
      <div style="width:100%;font-size:1.2em;" class="mb-1">
        {{ rule.name }}
      </div>
      <v-list-item-subtitle>
        <template v-for="path in rule.policy_paths">
          <template v-if="path.policy">
            <div :key="path.id">
              {{ path.policy.friendly_name }}
              ({{ path.policy.identifier }})
              <br />
              <span v-if="avoid_bridge_cas">Avoid Bridge CAs</span>
            </div>
          </template>
          <template v-else>Ignore Policies</template>
        </template>
      </v-list-item-subtitle>
    </v-list-item-title>
    <v-list-item-action-text style="width:300px">
      <v-btn
        color="secondary"
        @click="
          dialog_type = 'cert';
          dialog = true;
        "
        x-small
        >{{ rule_uris.certs.length }} Certificates
      </v-btn>

      <br />

      <v-btn
        color="primary"
        x-small
        class="mr-1"
        @click="
          dialog_type = 'AIA';
          dialog = true;
        "
      >
        {{ rule_uris.aia.length }} AIA
      </v-btn>
      <v-btn
        color="primary"
        x-small
        class="mr-1"
        @click="
          dialog_type = 'SIA';
          dialog = true;
        "
      >
        {{ rule_uris.sia.length }} SIA
      </v-btn>
      <v-btn
        color="primary"
        x-small
        class="mr-1"
        @click="
          dialog_type = 'CRL';
          dialog = true;
        "
      >
        {{ rule_uris.crl.length }} CRL
      </v-btn>
      <v-btn
        color="primary"
        x-small
        class="mr-1"
        @click="
          dialog_type = 'OCSP';
          dialog = true;
        "
      >
        {{ rule_uris.ocsp.length }} OCSP
      </v-btn>
    </v-list-item-action-text>
  </v-list-item>
</template>

<script>
import CertificateTable from "../../CertificateTable";
import UserService from "../../../store/UserService";
import GroupRuleUriTable from "./UriTable";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default {
  name: "PathRule",
  components: { GroupRuleUriTable, CertificateTable },
  props: ["rule", "token", "group"],
  data: function() {
    return {
      dialog: false,
      dialog_type: null,
      deleting: false,
      deleted: false,
      avoid_bridge_cas: null,
      saving: false,
      saved: false,
      error: false,
      editing: false,
      unsaved: null
    };
  },
  computed: {
    certs: function() {
      return this.rule_uris.certs;
    },
    rule_uris: function() {
      let aias = [],
        sias = [],
        crls = [],
        certs = [],
        ocsp = [];
      for (let x in this.rule.policy_paths) {
        let path = this.rule.policy_paths[x];
        for (let c in path.certificates) {
          let cert = path.certificates[c];
          certs = certs.concat(cert);

          if (cert.issued_uris) {
            aias = aias.concat(cert.issued_uris.aia_uris);
            sias = sias.concat(cert.issued_uris.sia_uris);
            crls = crls.concat(cert.issued_uris.crl_uris);
            ocsp = ocsp.concat(cert.issued_uris.ocsp_uris);
          }
        }
      }
      return {
        aia: aias.filter(onlyUnique),
        sia: sias.filter(onlyUnique),
        crl: crls.filter(onlyUnique),
        ocsp: ocsp.filter(onlyUnique),
        certs: certs.filter(onlyUnique)
      };
    }
  },
  created: function() {
    this.avoid_bridge_cas = this.rule.avoid_bridge_cas;
  },
  methods: {
    delete_rule: function() {
      let self = this;
      self.deleting = true;

      UserService.deleteLocalApi("monitoring-group-path-rules/" + this.rule.id)
        .then(() => {
          this.$store.dispatch("loadUserSettings", true);
          self.deleted = true;
          self.deleting = false;
        })
        .catch(function(error) {
          self.deleting = false;
          console.log(error);
        });
    }
  }
};
</script>

<style scoped></style>
